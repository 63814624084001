
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css";

import HomeComp from './pages/homecomp'
import EcommerceComp from "./pages/ecomcomp"
import PriceComp  from "./pages/pricingcomp"
import TeamMembers from "./pages/teamcomp";
import RevenueComp from "./pages/revenuecomp"

function App() {

  const location = useLocation()
  const navigate = useNavigate()

  return (
    <div>       
      <Routes>
        <Route path="/pricing" exact  element={<PriceComp location={location} navigate={navigate} />} /> 
        {/* <Route path="/developers" exact  element={<HomeComp location={location} navigate={navigate} />} />  */}
        <Route path="/payvenue" exact  element={<RevenueComp location={location} navigate={navigate} />} /> 
        <Route path="/leapex" exact  element={<EcommerceComp location={location} navigate={navigate} />} /> 
        <Route path="/aboutus" exact element={<TeamMembers location={location} navigate={navigate} />} />
        <Route path="/" exact  element={<HomeComp location={location} navigate={navigate} />} />         
      </Routes>
    </div>
  );

}


export default App;

