
import {Footer, FAQ, GetStarted} from "./homecomp";
import {HeaderNav} from "../component/headercomp"
import {HiOutlineArrowNarrowRight} from "react-icons/hi"
import ImgCmp from "../component/images"
import IMGREF from "../image";
import Accordion from 'react-bootstrap/Accordion'

export default function RevenueComp(props){

    return (<>
        <section className="dottedbg blue-contrast">
            <HeaderNav active={'revenuecol'} className={'blue-contrast dottedbg'} />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="minheight center d-grid justify-content-center align-items-center mt5">
                            <p className="bgtext center blue_text montsert-bold"> integrated  revenue solution 
                            <span style={{color: "#000"}}> for </span> <br/>
                            revenue collection, <br/> monitoring <span style={{color: "#000"}}> and </span> evaluation
                            </p>
                            <p className="center smtext"> for businesses, ngo's and government agencies. </p>
                           
                            <div className="center">
                                <GetStarted classname="btn-lg col-md-6" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <br/>
            
            <ImgCmp src={IMGREF.LAPTOPTICS} className={"img-fluid w-100"} 
                style={{height: "650px"}}  
            />
        </section>
        <section className="blue-contrast">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="bgwhite bigcurve d-flex flex-row mt5 mbt10">
                            <div className="col-md-6 padh3 padv5 justify-content-around">
                                <h2 className="blue_text montsert-bold bgtext"> complete vehicle e-registration in 30 minutes  </h2>
                                <br/>
                                <p className="blue_text smtext padv3 mbt5 padh5 leftborder">
                                    
                                    a. verify taxpayers' BVN <br/>                                    
                                    b. generate reports within minutes 
                                </p>
                                <p className="blue_text smtext padv3 mbt5 padh5 leftborder">
                                    automated vehicle registration and verification system 
                                </p>
                                <p className="blue_text smtext padv3 mbt10 padh5 leftborder">
                                    digital wallet & e-registration
                                </p>
                                <p className="center mt2">
                                    <GetStarted classname="btn-lg col-md-6" />
                                </p>

                            </div>
                            <div className="col-md-6 padv5 padh3 right">
                                <ImgCmp src={IMGREF.BOYDEV} className={"img-fluid"} 
                                    style={{borderTopRightRadius: "20px", borderBottomRightRadius: "20px" }}  />
                            </div>  

                        </div>
                         

                    </div>
                </div>
            </div>
            <div className="padv10">
            
            <div style={{backgroundColor: "#E8F4FBE3"}}>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="padv10">
                                <h2 className="montsert-bold bgtext blue_text d-flex justify-content-between">
                                    <span className="w-70">
                                        hassle-free online payments solution for sectors and businesses...
                                    </span>
                                    <span style={{fontSize: "16px", width: "30%"}}>
                                        <GetStarted classname={"w-100 btn-lg"} /> 
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </div>

        </section>
        <section className="blue-contrast padv3">

            <div className="container">
                <div className="row mbt5">
                    <style>{`

                        .h400 {
                            height: 400px;
                        }
                    `}
                    </style>
                    <div className="col-md-4">
                        <div className={"bgwhite h400 padh10 padv10 circle-5 center"}>
                            <center>
                            <p className="circle square90 alert-primary d-flex justify-content-center align-items-center">
                                <ImgCmp src={IMGREF.TEAMSP} />
                            </p>
                            </center>
                            
                            <p className="blue_text montsert-bold">
                                automated vehicle registration & verication system
                            </p>
                            <p className="montsert-reg">                            
                                payVenue is equipped with modern technological features to help you verify all vehicle documents on the go,
                                our solution helps you handle your motor vehicle registration with ease.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={"bgwhite h400 padh10 padv10 circle-5 center"}>
                            <center>
                            <p className="circle square90 alert-primary d-flex justify-content-center align-items-center">
                                <ImgCmp src={IMGREF.TEAMSP} />
                            </p>
                            </center>
                            
                            <p className="blue_text montsert-bold">
                                easy-to-use self-service dashboard
                            </p>
                            <p className="montsert-reg">
                                are you a taxpayer?, you can now generate your Taxpayer Identification Numbers (TIN), 
                                generate invoices and proceed to make payments

                            </p>
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className={"bgwhite h400 padh10 padv10 circle-5 center"}>
                            <center>
                            <p className="circle square90 alert-primary d-flex justify-content-center align-items-center">
                                <ImgCmp src={IMGREF.TEAMSP} />
                            </p>
                            </center>
                            
                            <p className="blue_text montsert-bold">
                                24/7 digital wallet & e-rgistration
                            </p>
                            <p className="montsert-reg">
                                with the introduction of the Digital Wallet system to PayVenue, 
                                vehicle e-registrations can be completed in less than 30 minutes,
                                irrespective of the day of the week and time.
                            </p>
                        </div>

                    </div>                    
                </div>

                <div className="row">
                    <div className="col-md-4">
                        <div className={"bgwhite h400 padh10 padv10 circle-5 center"}>
                            <center>
                            <p className="circle square90 alert-primary d-flex justify-content-center align-items-center">
                                <ImgCmp src={IMGREF.TEAMSP} />
                            </p>
                            </center>
                            
                            <p className="blue_text montsert-bold">
                                automated tax calculating module 
                            </p>
                            <p className="montsert-reg">
                                adequately and correctly calculate taxes for individuals and corporate organizations, 
                                directly on the system with payVenue automatically calculate Pay As You Earn (PAYE) 
                                and Withholding Taxes. 

                           </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={"bgwhite h400 padh10 padv10 circle-5 center"}>
                            <center>
                            <p className="circle square90 alert-primary d-flex justify-content-center align-items-center">
                                <ImgCmp src={IMGREF.TEAMSP} />
                            </p>
                            </center>
                            
                            <p className="blue_text montsert-bold">
                                user role permission auto generated report
                            </p>
                            <p className="montsert-reg">

                                reports can be generated to view revenue collections per individual taxpayer, MDAs, Revenue Heads,
                                collections from individual banks, etc for any time duration (day, week, month and year). 

                            </p>
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className={"bgwhite h400 padh10 padv10 circle-5 center"}>
                            <center>
                            <p className="circle square90 alert-primary d-flex justify-content-center align-items-center">
                                <ImgCmp src={IMGREF.TEAMSP} />
                            </p>
                            </center>
                            
                            <p className="blue_text montsert-bold">
                                real-time notification 
                            </p>
                            <p className="montsert-reg">
                               get notified via email of any activities on your platform, and efficiently monitor 
                               the activities of members of your team from anywhere in the world and at any time. 

                            </p>
                        </div>
                    </div>
                    
                </div>

            </div>
        </section>

        <FAQ />
        
        <Footer />
    </>

    )
}