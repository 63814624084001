import {Footer, GetStarted} from "./homecomp";
import {HeaderNav} from "../component/headercomp"
import {HiOutlineArrowNarrowRight} from "react-icons/hi"
import ImgCmp from "../component/images"
import IMGREF from "../image";




export default function TeamMembers (props){

    return (<>
        <section className="dottedbg blue-banner">
            <HeaderNav active={'teammember'} className={'blue-banner dottedbg'} />
            <div className="container">
				<div className="row">
					<div className="col-md-12">						
						<div className="d-flex justify-content-center align-items-center" style={{height: "500px"}}>
							<div className="mt10">
                                <h2 className="montsert-bold bgtext center"> 
                                 <span className="blue_text"> we understand that business is hard; getting paid shouldn't be </span> 
                                </h2>							
                                <p className="smtext center">
                                    seamlessly integrate payment with your lifestyle, automatically pay bills, accept funds from anywhere in the world without stress
                                </p>                               
                                <p className="center mt10">
                                    <GetStarted classname={"btn-lg col-md-6"} />
                                </p>
							</div>
						</div>
  					</div>
				</div>
			</div>

        </section>
        <section className="midstipes-1">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="bgwhite bigcurve d-flex flex-row mt5 mbt10">
                            <div className="col-md-6 padh5 padv5 justify-content-around">
                                <h2 className="blue_text bgtext montsert-bold"> who are we </h2>
                                <br/>
                                <p className="montsert-reg">  
                                    leapepay is a technology company that develops financial infrastructure for individuals, SMEs, 
                                    Multinationals and public establishments through payment solutions driven by data, 
                                    a two-way network that integrates P2P payment systems and centralised API monitored products.

                                </p>
                                <br/>
                                <p className="montsert-reg"> 
                                    at leapepay we are revolutionizing the digital economy by syncing consumers' 
                                    lifestyles with digital payment solutions. We provide payment gateway and financial 
                                    management with personalized data that helps individuals and companies organize their finances efficiently.

                                
                                </p>
                                <br /> <br />
                                <a style={{textDecoration: "none"}}>
                                <p className="blue_text smtext">
                                    meet our wonderful team &nbsp;<HiOutlineArrowNarrowRight size={20} />
                                </p>
                                </a>
                            </div>
                            <div className="col-md-6">
                                <ImgCmp src={IMGREF.MEETROOM} className={"img-fluid"} 
                                    style={{borderTopRightRadius: "20px", borderBottomRightRadius: "20px" }}  />
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="midstipes-2">

            <div className="container">
                <div className="row">   
                    <div className="col-md-12">

                        <h1 className="center bgtext montsert-bold mbt5"> our values at leapepay drives us </h1>

                        <div className="row">
                            <div className="col-md-4">
                                <div>
                                    <p className="circle square120 alert-primary d-flex justify-content-center align-items-center">
                                        <ImgCmp src={IMGREF.TEAMSP} />
                                    </p> 
                                    <p className="blue_text montsert-reg">
                                        teamwork
                                    </p>
                                    <p className="montsert-reg">
                                    our team consists of the best techies who are passionate about solving real-life problems with global standard lifestyle payment solutions. 

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <p className="circle square120 alert-success d-flex justify-content-center align-items-center">
                                        <ImgCmp src={IMGREF.TRANSP} />
                                    </p>  
                                    <p className="blue_text montsert-reg">
                                        fit-for-purpose solutions 
                                    </p>
                                    <p className="montsert-reg">
                                    at leapepay we are passionate about helping individuals and businesses access payment solutions that suit their lifestyles
                                     </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <p className="circle square120 alert-success d-flex justify-content-center align-items-center">
                                        <ImgCmp src={IMGREF.RELIABLE} />
                                    </p>  
                                    <p className="blue_text montsert-reg">
                                        security-first approach
                                    </p>
                                    <p className="montsert-reg">
                                    a foremost lifestyle payment solution company, at LeapePay, our platform allows users to pay merchants and businesses within the most secure environment. 
                                     </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <p className="circle square120 alert-success d-flex justify-content-center align-items-center">
                                        <ImgCmp src={IMGREF.GMINDS} />
                                    </p>  
                                    <p className="blue_text montsert-reg">
                                        customer first approach
                                    </p>
                                    <p className="montsert-reg">
                                        we believe our customers are the centre of what we do at LeapePay; 
                                        lifestyle payment solution means our solutions are designed to ensure accessibility and usability
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                            <div>
                                    <p className="circle square120 alert-success d-flex justify-content-center align-items-center">
                                        <ImgCmp src={IMGREF.HEALTH} />
                                    </p>  
                                    <p className="blue_text montsert-reg">
                                        innovations in technology 
                                    </p>
                                    <p className="montsert-reg">
                                    our team of experts are trained to provide innovative and cutting-edge 
                                    solutions to ensure seamless and easy payments that suit our customers' lifestyles
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                            <div>
                                    <p className="circle square120 alert-warning d-flex justify-content-center align-items-center">
                                        <ImgCmp src={IMGREF.WGADETS} />
                                    </p>  
                                    <p className="blue_text montsert-reg">
                                        cost efficiencies and security
                                    </p>
                                    <p className="montsert-reg">
                                     at LeapePay we understand that an efficient and secure payment solution 
                                     does not have to be expensive, we pride ourselves on providing efficient, 
                                     easy-to-use and secure technology.
                                    </p>
                                </div>
                            </div>


                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="center mt10">
                            <p>
                                <ImgCmp src={IMGREF.MARKER} />
                            </p>
                            <h2 className="midtext blue_text"> our office  </h2>
                            <p> 8a reeve road, ikoyi 101233, lagos, nigeria </p>

                        </div>

                        <div className="center mt10 mb5">
                        <p>
                                <ImgCmp src={IMGREF.ENVELOPE} />
                            </p>
                            <h2 className="midtext blue_text"> mail us  </h2>
                            <a style={{textDecoration: "none"}} className="blue_text" href="mailto:contact@leapepay.com"> <p>  contact@leapepay.com </p> </a>

                        </div>

                    </div>
                </div>

            </div>
        </section>
        <Footer />
    
    </>


    )


}
