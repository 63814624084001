
import {useState} from 'react'
import {Footer, GetStarted} from "./homecomp";
import {HeaderNav} from "../component/headercomp"
import {HiOutlineArrowNarrowRight} from "react-icons/hi"
import ImgCmp from "../component/images"
import IMGREF from "../image";
import {CgPhone} from "react-icons/cg";

function PricingComp(props){

	const [remit_amount, setRemitAmt] = useState("19,700") ; 
	const [commission, setCommission] = useState(300) ; 
	const [input, setInput] = useState(20000);

	function handlechange(e){
		
		setInput(e.target.value);
		let input_amt = Number(e.target.value)
		if (isNaN(input_amt)){
			input_amt = 0 ;
		}
		let comm = 0.015 * input_amt ; 
		if (comm > 2000 )
			comm = 2000 

		let rem = input_amt - comm; 
		
		setRemitAmt(rem.toFixed(2));
		setCommission(comm.toFixed(2))



	}

	return (<>
		<section className={'blue-banner dottedbg'}>
			<HeaderNav active={'price'} className={'blue-banner dottedbg'} />
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="minheight d-flex justify-content-center align-items-center">
								<div className="center">
									<h1 className="montsert-bold blue_text bgtext"> zero hidden charges </h1>
									<p className='montsert-reg smtext'> no hidden charges, zero surprises, one of the best plans for you</p>
									<br/> <br/>
									<GetStarted text="dive in" classname="btn-lg col-md-6" />
								</div>
						</div>
					</div>
				</div>
			</div>

		</section>
	<section className="midstipes-1">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					 
					<div className='mt10'>
						
						<div className="mbt10 posrel">
							
						
							<div className="row">
								
							<div className="col-md-5 offset-md-1">
								<div className="blue-shade curve padv10 padh10">
									<h2 className="white_text bgtext montsert-bold center padh10 padv5 borderbottom-white"> LeapeX</h2>
									<ul className={"padv3"}>
											<li className={'d-flex flex-row'}> 
												<p className={'w-50 montsert-reg nombt white_text padh2 padv2  border-right-dashed'}> Transactions </p> 
												<p className={"w-50 montsert-reg nombt white_text padh3 padv2 "}> Transfer fee </p> 
											</li>
					 						<li className={'d-flex flex-row'}> 
												<p className={'w-50 montsert-reg nombt white_text border-right-dashed padh2 padv2'}> pay with POS </p> 
												<p className={"w-50 montsert-reg nombt white_text padh3 padv2"}> 0.3% </p> 
											</li>
											<li className={'d-flex flex-row'}> 
												<p className={'w-50 montsert-reg nombt white_text border-right-dashed padh2 padv2'}> pay with bank transfer </p> 
												<p className={"w-50 montsert-reg nombt white_text padh3 padv2"}> 0.5% </p> 
											</li>
											<li className={'d-flex flex-row'}> 
												<p className={'w-50 montsert-reg nombt white_text border-right-dashed padh2 padv2'}> pay with wallet </p> 
												<p className={"w-50 montsert-reg nombt white_text padh3 padv2"}> 0.5% </p> 
											</li>

										</ul>
								</div>
							</div>
							<div className="col-md-5">
									<div className="blue-shade curve padv10 padh10">
										<h2 className="white_text bgtext montsert-bold center padh10 padv5 borderbottom-white"> Payvenue </h2>
										<ul className={"padv3"}>
											<li className={'d-flex flex-row'}> 
												<p className={'w-50 montsert-reg nombt white_text padh2 padv2  border-right-dashed'}> Transactions </p> 
												<p className={"w-50 montsert-reg nombt white_text padh3 padv2 "}> Transfer fee </p> 
											</li>
					 						<li className={'d-flex flex-row'}> 
												<p className={'w-50 montsert-reg nombt white_text border-right-dashed padh2 padv2'}> pay with POS </p> 
												<p className={"w-50 montsert-reg nombt white_text padh3 padv2"}> 0.3% </p> 
											</li>
											<li className={'d-flex flex-row'}> 
												<p className={'w-50 montsert-reg nombt white_text border-right-dashed padh2 padv2'}> pay with bank transfer </p> 
												<p className={"w-50 montsert-reg nombt white_text padh3 padv2"}> 0.5% </p> 
											</li>
											<li className={'d-flex flex-row'}> 
												<p className={'w-50 montsert-reg nombt white_text border-right-dashed padh2 padv2'}> pay with wallet </p> 
												<p className={"w-50 montsert-reg nombt white_text padh3 padv2"}> 0.5% </p> 
											</li>

										</ul>
									</div>
							</div>

							</div>

							<ImgCmp src={IMGREF.CIRCLE} className={"img-fluid"} 
								style={{position: "absolute", left: "-35em",top: "35px"}} 
							/>
				
						</div>

						<h1 className='montsert-bold bgtext blue_text center'> find out how much you pay with our fee calculator </h1>
						<p className="smtext center mbt4"> See how much it costs to use leape. Enter an amount into the calculator to see our charges. </p>

					 	<div className="row">
					 		<div className="col-md-6 offset-md-3">
					 			<div className="padv5 padh5 curve mt5 blue-shade">
					 				<div className="mb-4">
					 					<label htmlFor="amnt" className='white_text ssmtext'> your customer sends </label>
					 					<input type="text" className='form-control form-control-lg' onChange={handlechange} value={input} style={{borderRadius: "20px",backgroundColor: "#05055A", color: "#fff", fontSize: '38px'}} />
					 				</div>
					 				<p className={'d-flex justify-content-between'} style={{color: '#ccc'}}>
					 					<p> 
					 						you'll get <br/>
					 						<span className='midsmtext white_text'>&#8358; { remit_amount }</span>
					 					</p>
					 					<p> we get (1.5% fee) <br/> 
					 					<span className={'midsmtext white_text'}>
					 							&#8358; {commission}
					 					</span>
					 					</p>
					 				</p>

					 			</div>
					 		</div>
					 	</div>	

				 	</div>
				</div>
			</div>
		</div>
	</section>
	<section className={'midstipes-2'}>

		<div className="container"> 
			<div className="row">
				<div className="col-md-12 posrel">
					<div className={"minheight shadow curve mbt10 mt10 posrel"} style={{"backgroundColor": "#F7F9F7", position: 'relative', zIndex: 12}}>
						
						<ul className={"padv3"}>
							<li className={'d-flex flex-row'}> 
								<p className={'w-60 montsert-reg nombt  padh2 padv2  border-right-dashed'}> cash deposit </p> 
								<p className={"w-30 montsert-reg nombt padh3 padv2 "}> free </p> 
							</li>
	 						<li className={'d-flex flex-row'}> 
								<p className={'w-60 montsert-reg nombt border-right-dashed padh2 padv2'}> transfer to non leape account </p> 
								<p className={"w-30 montsert-reg nombt padh3 padv2"}> standard fee </p> 
							</li>
							<li className={'d-flex flex-row'}> 
								<p className={'w-60 montsert-reg nombt border-right-dashed padh2 padv2'}> dispute settlement </p> 
								<p className={"w-30 montsert-reg nombt padh3 padv2"}> 5% fee </p> 
							</li>
							<li className={'d-flex flex-row'}> 
								<p className={'w-60 montsert-reg nombt border-right-dashed padh2 padv2'}> monthly fees </p> 
								<p className={"w-30 montsert-reg nombt padh3 padv2"}> free </p> 
							</li>
							<li className={'d-flex flex-row'}> 
								<p className={'w-60 montsert-reg nombt border-right-dashed padh2 padv2'}> overdraft fees </p> 
								<p className={"w-30 montsert-reg nombt padh3 padv2"}> free </p> 
							</li>
							<li className={'d-flex flex-row'}> 
								<p className={'w-60 montsert-reg nombt border-right-dashed padh2 padv2'}> card maintenance fee </p> 
								<p className={"w-30 montsert-reg nombt padh3 padv2"}> free </p> 
							</li>
							<li className={'d-flex flex-row'}> 
								<p className={'w-60 montsert-reg nombt border-right-dashed padh2 padv2'}> api integration fee </p> 
								<p className={"w-30 montsert-reg nombt padh3 padv2"}> free </p> 
							</li>

							<li className={'d-flex flex-row'}> 
								<p className={'w-60 montsert-reg nombt border-right-dashed padh2 padv2'}> cash withdrawal </p> 
								<p className={"w-30 montsert-reg nombt padh3 padv2"}> free </p> 
							</li>

							<li className={'d-flex flex-row'}> 
								<p className={'w-60 montsert-reg nombt border-right-dashed padh2 padv2'}> local transactions </p> 
								<p className={"w-30 montsert-reg nombt padh3 padv2"}> free </p> 
							</li>

						</ul>					
						
					</div>
					<ImgCmp src={IMGREF.CIRCLE} className={"img-fluid"} 
							style={{position: "absolute", right: "-15em",top: "0px", zIndex: 1}} 
						/>
				</div>
				<div className="col-md-6">
					<div className="minheight d-flex justify-content-center align-items-center">
						<div>						
						<h2 className='airbnc-bold midtext blue_text'> talk to our support team </h2>
						<p className="montsert-reg smtext"> still have more questions? <br/> give us a call. </p>

						<h3 className="blue_text montsert-reg"> <CgPhone size={60} /> &nbsp; +234 808 888 0707  </h3>

						</div>

					</div>

				</div>
				<div className="col-md-6">

					<div>
						<ImgCmp src={IMGREF.OFFICEGIRL} className='img-fluid curve'/>
					</div>
				</div>
			</div>
		</div>
	</section>

	<Footer />
	</>)
}




export default PricingComp;
