import Logo from "../images/logo.png"
import {Link} from "react-router-dom"
import {DropdownButton, Dropdown} from "react-bootstrap"


export function HeaderNav (props){

	return (<nav className={props.className? `navbar navbar-expand-lg ${props.className}` : 'navbar navbar-expand-lg bg-light dottedbg' }>
		<div className="container-fluid">
		    <Link className="navbar-brand" to="/">
		      <img src={Logo} alt="" width="80" height="30" className="d-inline-block align-text-top"/>
		    </Link>
		    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
		      <span className="navbar-toggler-icon"></span>
		    </button>
		    <div className="collapse navbar-collapse blue_text" id="navbarNav">
		      <ul className="navbar-nav col-md-4 offset-md-4 justify-content-between">
		        <li className="nav-item">		          		          
				    <DropdownButton
						variant="nav-link"
						title="product"
						id="input-group-dropdown-1"
					>
						<Link to="/" className="dropdown-item"> leape </Link>
						<Dropdown.Divider />					
						<Link to="/leapex" className="dropdown-item"> leapex </Link>
						<Dropdown.Divider />					
						<Link to="/payvenue" className="dropdown-item"> payvenue </Link>					
					</DropdownButton>

		        </li>
		        <li className="nav-item">
		          <Link className="nav-link" to="/pricing"> pricing </Link>
		        </li>
		        <li className="nav-item">
		          <Link className="nav-link" to="/aboutus">company</Link>
		        </li>
		        <li className="nav-item">
		          <Link className="nav-link" to="/">developers</Link>
		        </li>		        
		      </ul>

		      <ul className='navbar-nav col-md-3 justify-content-end'>
		      		
		      		<li className="nav-item"> <Link to="/" className='btn btn-sm'> Login </Link> </li>
		      		<li className="nav-item">
		      			<Link to="/" className="btn btn-sm btn-primary"> get started </Link>		      			
		      		</li> 

		      </ul>
		    </div>


	  	</div>



	</nav>


	)

}