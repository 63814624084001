

import {Footer, FAQ, GetStarted} from "./homecomp";
import {HeaderNav} from "../component/headercomp"
import {HiOutlineArrowNarrowRight} from "react-icons/hi"
import ImgCmp from "../component/images"
import IMGREF from "../image";





export default function EcomComp (props){
 	

	return (<>
		<section className="dottedbg">
			<HeaderNav active={'company'} />

			<div className="container">
				<div className="row">
					<div className="col-md-12">
						
						<div className="d-flex justify-content-center align-items-center" style={{height: "450px"}}>
							<div>
							<h2 className="montsert-bold bgtext center"> leapeX-simplified <span className="blue_text"> payment lifestyle <br/>for ecommerce merchants</span> </h2>							

							<p className="blue_text smtext center"> 
								powering small and medium-size businesses; <br/>
							 	a fully unified suite of digital payments products for businesses. 
							</p>
							<br/>
							<p className="center">								
								<GetStarted classname="btn-lg col-md-6" text="dive in"/>								
							</p>
							</div>
						</div>

 						
					</div>
				</div>
			</div>
			<div>
				<ImgCmp src={IMGREF.BOYGIRL} className={"img-fluid"} style={{height: "500px", width: "100%"}} />				
			</div>

		</section>
		<section>
			<div className="container mt10">
				<div className="row">
					<div className="col-md-6">
						<div className='mbt10'>							
							<div className={'padv20 padh20 midboxarea'}>
								<div className="">
									<p className='smtext blue_text montsert-bold'> secure online payment channel </p>
									<ImgCmp src={IMGREF.PHONEMAN} className={'img-fluid'}  style={{height: "320px", borderRadius: "15px"}}/>
								 </div>
							</div>							
						</div>
					</div>
					<div className="col-md-6">					
						<div className="d-flex align-items-center flex-column justify-content-center minheight">
							<p className='w-60 montsert-reg smtext'>
								efficient and secure channel for online payment <br/> transactions in Africa and beyond 
							</p>
							<button className="btn btn-lg bgwhite gray_border col-md-6"> get started <HiOutlineArrowNarrowRight size={20} /> </button>
						</div>						
					</div>

					<div className="col-md-6">

						<div className="d-flex align-items-center flex-column justify-content-center minheight">
							<p className='w-60 montsert-reg smtext'>
								integrate leapeX on your website with little or no help from developers <br/>
								with our easy step by step integration processes 
								
							</p>
							<button className="btn btn-lg bgwhite gray_border col-md-6"> get started <HiOutlineArrowNarrowRight size={20} /> </button>
						</div>	

					</div>

					<div className="col-md-6">
						<div className='posrel mbt10'>		
						<div className={'padv20 padh20 midboxarea-right'}>							 
							<div className="">
								<p className='smtext blue_text montsert-bold'> quick and easy integration </p>
								<ImgCmp src={IMGREF.SHOPING} className={'img-fluid'}  style={{height: "320px", borderRadius: "15px"}}/>
							</div> 
							 
						</div>
				 
						</div>
					</div>
					
					<div className="col-md-6">
						<div className='posrel  mbt10'>								
							<div className={'padv20 padh20 midboxarea'}>
								<div className="">
									<p className='smtext blue_text montsert-bold'> automatic wallet system for businesses </p>
									<ImgCmp src={IMGREF.REMOTE} className={'img-fluid'}  style={{height: "320px", borderRadius: "15px"}}/>
								 </div>
							</div> 
						</div>
					</div>


					<div className="col-md-6">					
						<div className="d-flex align-items-center flex-column justify-content-center  minheight">
							<p className='w-60 montsert-reg smtext'>
								receive your payments for goods and services across Africa through leapeX
								<br/> and transfer them to bank accounts with a single click
							</p>
							<button className="btn btn-lg bgwhite gray_border col-md-6"> get started <HiOutlineArrowNarrowRight size={20} /> </button>
						</div>						
					</div>


					<div className="col-md-6">

						<div className="d-flex align-items-center flex-column justify-content-center  minheight">
							<p className='w-60 montsert-reg smtext'>
								with leapeX, merchants can send and receive payment from customers from anywhere in the world.
							</p>
							<button className="btn btn-lg bgwhite gray_border col-md-6"> get started <HiOutlineArrowNarrowRight size={20} /> </button>
						</div>	

					</div>

					<div className="col-md-6">
						<div className='posrel  mbt10'>		
							<div className={'padv20 padh20 midboxarea-right'}>							 
								<div className="">
									<p className='smtext blue_text montsert-bold'> electronic invoicing system </p>
									<ImgCmp src={IMGREF.MOBILE} className={'img-fluid'}  style={{height: "320px", borderRadius: "15px"}}/>
								</div>								
							</div> 
						</div>
					</div>

				</div>
			</div>
		</section>
	
		<Footer />
	</>


	)


}