

import {HeaderNav} from "../component/headercomp"
import ImgCmp from "../component/images"
import IMGREF from "../image";
import {HiOutlineArrowNarrowRight} from "react-icons/hi"
import {BsDot} from "react-icons/bs"
import Accordion from 'react-bootstrap/Accordion'


export function GetStarted(props){

	const classname = props.classname ? `btn btn-primary ${props.classname}`: "btn btn-lg btn-primary"
	return (<button className={classname}> {props.text? props.text : "get started"} <HiOutlineArrowNarrowRight size={20} /> 
	</button>
	)
}
function Home(props){

	return (<>
		<section className={'dottedbg'}>
			<HeaderNav active={'home'} />

			<div className="container">
				<div className="row">
					<div className="col-md-7" style={{minHeight: '500px'}}>
						<div className={"d-flex align-items-center"} style={{minHeight: "500px"}}>							
							<div> 
								<h1 className="montsert-bold bgtext"> syncing <span className='blue_text'> lifestyle </span> with </h1>
								<h1 className="montsert-bold bgtext"> simplified <span className='blue_text'>payment</span>!</h1>
								<p className='smtext blue_text'> for businesses, individuals, freelancers, entrepreneurs.</p>
								<div className='center d-grid w-50 mt5'>
									<GetStarted classname="btn-lg" />								
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-5">
						<div style={{position: 'relative', top: 40, minHeight: "600px", paddingRight: "30px"}}>
							<div style={{display: 'flex', justifyContent: 'center'}}>
								<p className="bgblue" style={{width: "280px", position: "relative", bottom: -60, height: "300px", borderRadius: '20px', }}>
									<ImgCmp src={IMGREF.MAN} style={{height: "28em", position: "absolute", bottom: 5}}/> 
								</p>
								
							</div>
							<div>
								<p className="bgwhite" 
									style={{width: "300px", position: "relative", bottom: 30, 
										height: "280px", borderRadius: '20px', paddingBottom: "30px"}}>									
									<ImgCmp src={IMGREF.GIRL} style={{height: "22em", position: "absolute", bottom: 20, right: 20}}/>									
								</p>
							</div>
						</div>
						
					</div>
					
					<div className='col-md-12 center'>
						<p className="blue_text"> discerning businesses trust leape to scale their operations:</p>

						<p className="center">
							<ImgCmp src={IMGREF.PARTNERS} />
						</p>
					</div>
				</div>
			</div>

		</section>
		<section>
			<div className="container mt5">
				<div className="row">
					<div className="col-md-12">
						<h1 className="bigtext bold text-center mbt5">  <span className='blue_text'>one-stop</span> platform for all your 
							<span className='blue_text bordertop'> payment </span> needs
						</h1>

						<div className='bgblue curve'>
							
							<div className='padv5 padh2'>
								<div className="row">

									<div className="col-md-5">
									    <div style={{position: "relative"}}>										
										   <ImgCmp src={IMGREF.WALLET} 
										   		style={{position: "relative", zIndex: "10"}} 
										   		className='rounded img-fluid' 
										   />
										   <ImgCmp src={IMGREF.CIRCLE_RIGHT} className='rounded img-fluid'
										   		style={{position: "absolute", zIndex: 1, left:"-12px", height: "350px", top:"-20px"}}
										    />
									    </div>
									</div>
									<div className="col-md-7">

										<p className={'midtext white_text padv3'} style={{borderRight: "solid 2px #2F80ED"}}> innovative payment solution </p>

										 
										<p className={"smtext gray_text padv3"} style={{borderRight: "solid 2px #eee"}}>
											<BsDot size={20} /> &nbsp; {" "} one login for everything leapepay 
										</p>												
									 
										<p className={"smtext gray_text padv3"} style={{borderRight: "solid 2px #eee"}}><BsDot size={20} /> &nbsp; {" "}  premium support for everyone </p>												
								 												
										<p className={"smtext gray_text padv3"} style={{borderRight: "solid 2px #eee"}}><BsDot size={20} /> &nbsp; {" "}  pay and receive money with just a click </p>
										 
										 <div className="d-grid mt5">
										 	<button className={"btn btn-info btn-lg col-md-5"}>
										 		get started  <HiOutlineArrowNarrowRight size={20} />
										 	</button>
										 </div>
									</div>
								</div> 
							</div>
						</div>

					</div>
				</div>
		 
			</div>
		</section>
		<section>

			<div className='skyblue' style={{marginTop: "10%"}}>
				<div className="padv5 padh5">

					<div className="container">
						<div className="row">
							<div className="col-md-6">

								<p className="bgtext bold nombt"> <span className='blue_text'> businesses </span> and </p>
								<p className="bgtext bold nombt"> <span className='blue_text'> individuals </span> can <span className='blue_text'> sync </span> </p>
								<p className="bgtext bold mbt5"> <span className='blue_text'> payment </span> with  <span className='blue_text'>lifestyle </span></p>
							
								<div className='center d-grid w-50 mt5'>
									<button className="btn btn-lg btn-primary">
										get started  <HiOutlineArrowNarrowRight size={20} />
									</button>
								</div>
							</div>
							<div className="col-md-6">
								<div className="posrel">
									<div>
										<ImgCmp src={IMGREF.FLYINGGIRL}  className={"img-fluid"} style={{height: "35em", position:"absolute", top: "-180px"}}/>									
										<ImgCmp src={IMGREF.SERIOUSMAN } className={"img-fluid"} style={{height: "35em", position: "absolute", right: "0px"}}/>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
			
		</section>
		<section>
			<div className="container mt3">
				<div className="row">
					
					<div className="col-md-12">
						<h2 className='bgtext bold mbt5'> our <span className='bordertop'> products </span> </h2>
						<div className="row justify-content-center">
							<div className="col-md-5">
								<div id="leapex_frs" className={'padv10 padh10'}>
									<h3 className='midsmtext bold'> <span className="bordertop"> LeapeX </span>  </h3>	
									<ImgCmp  className={'img-fluid rounded'} style={{marginBottom: "3.5%"}} src={IMGREF.LEAPEX} />
									<p className="right d-flex justify-content-between">	
										<p className='left smtext white_text text-shadow w-60'>										 
										 a payment platform designed to help businesses and merchants receive payment 
										 for goods and services, anytime, anywhere
										</p>								
										<button className={'btn btn-primary btn-lg circle img-circle'}> 
											<HiOutlineArrowNarrowRight size={25} /> 
										</button>
									</p>
									<br/>
								</div>

							</div>
							<div className="col-md-5">								
								<div id="leapepayv" className={'padv10 padh10'}>									
									<h3 className="midsmtext bold">
										<span className="bordertop"> PayVenue  </span> </h3>
									<ImgCmp className={'img-fluid rounded'} style={{padding: "10px"}} src={IMGREF.PAYVENUE} />
									<p className="right d-flex justify-content-between" style={{paddingBottom: "15%"}}>	
										<p className='left smtext white_text text-shadow w-60'>
											all-in-one solution designed to handle the critical role of revenue collection 
											for government agencies and parastatals
										</p>								
										<button className={'btn btn-primary btn-lg circle img-circle'}> 
											<HiOutlineArrowNarrowRight size={25} /> 
										</button>

									</p>

								</div>

							</div>
						 							
						</div>

					</div>
				</div>

			</div>
		</section>
		<section>
			<div className="container mt20 mbt5">
				<div className="row">
					<div className="col-md-5">
						<div className={'posrel'}>
							<ImgCmp src={IMGREF.GIRLSMILE} style={{height: 250, borderRadius: 10}} className={'img-fluid'} />
						</div>
						<div className={'center posrel'}>
							<ImgCmp src={IMGREF.MANSMILE} className={'img-fluid posrel'} style={{height: 250, borderRadius: 20, top: "-50px", left: "-50px"}}/>							
						</div>							
						<div className={'posrel'}>
							<ImgCmp src={IMGREF.MANTHINK} className={'img-fluid posrel'} style={{height: 250, borderRadius: 20, top: "-100px"}} />
						</div>						
					</div>
					<div className="col-md-7">
						
						<h2 className='bgtext bold mt5 mbt5'> what we offer </h2>

							<div className="row">
							
								<div className="col-md-6">								
									<div className="">								
										<p className="img-circle skyblue">
											<ImgCmp src={IMGREF.SECURITY} className='rounded img-fluid' />
										</p>								
									</div>

									<p className='blue_text midsmtext lightbold'>
										24/7 support for individuals & businesses
									</p>
									<p>									
										whether you are a business, merchant or individual, your needs are prioritized with us at LeapePay.
									</p>

								</div>
								<div className="col-md-6">								
									<div className=''>
										<p className={"img-circle lemon"}>
											<ImgCmp src={IMGREF.INTEGRATION} className='rounded img-fluid'/>
										</p>									
									</div>
									<p className='blue_text midsmtext lightbold'>
										stress-free payment and withdrawal
									</p>
									<p>									
										convenient, error-free online payment experience; we understand that building a business is hard, getting paid shouldn't be. 
									</p>
								</div>
						 	</div> 
						 <div className="row mt-4">  
							<div className="col-md-6">	
								<div>									
									<div className='mbt5'>
										<p className={"img-circle yellow"}>									
											<ImgCmp src={IMGREF.EASY}  className='rounded img-fluid'/>
										</p>
										
									</div>					
	 
									<p className='blue_text midsmtext lightbold'>
										guaranteed security
									</p>
									<p>									
										access to top-notch payment security infrastructure so you can focus on growing your business and achieving your goals. 
									</p>
								</div>		
							</div>
							<div className="col-md-6">
								<div className="mbt5">
								<p className={"img-circle purple"}>									
									<ImgCmp src={IMGREF.HIDDENFEE} className='rounded img-fluid'/>
								</p>
									
								</div>

								<p className='blue_text midsmtext lightbold'>
									developers love us 
								</p>
								<p>									
									financial inclusivity that gives you a competitive edge, 
									integrate seamlessly with one api integration for multiple payment systems.
								</p>
							</div>
						</div>
						</div>
					

				</div>
			</div>					
		</section>
		
		<Footer />
		 
		</>
	)
}


export function Footer(props){

	 return (
        	<section className={'footer posrel'}>
            <div className="container posrel" style={{zIndex: 5}}>
	            <div className='row'>
					  	<div className="col-md-12 posrel">

					  		<div className="bgblue d-flex justify-content-center align-items-center posrel" style={{ minHeight: "250px", borderRadius: "10px", margin: "10% 5%", backgroundColor: '#05055A', zIndex: 5}}>

					  			<p className="center white_text w-50 smtext">
									<span className="gray_text" style={{fontSize: "0.65em"}}>it takes only a few minutes to open an account </span>
									<br />
									create a free account with us today
								</p> 
								<a className="btn btn-lg bgwhite" href="/">
									get started <HiOutlineArrowNarrowRight size={25} /> 
								</a>					  			
					  		</div>
							
							
				  		
				  		</div>
				  		
				  		<div style={{marginTop: '20%'}}>
				  			&nbsp;				  			
				  		</div>
				  		<div className="row posrel">		            					 
						<div className="col-md-3">
							<p className="">
								<ImgCmp cls='img-fluid rounded' src={IMGREF.LOGO} alt="leapepay.com" style={{width:'120px'}} />
								<br />
								copyright &copy; 2022  <br />
								leapepay.com
							</p>
							<br/>
						</div>
					
						<div className="col-md-3 footer-list">

							<p className='bold ssmtext'> social </p>
							<ul className="ul">
								<li className="">
									<ImgCmp src={IMGREF.WP} cls="tp-0" alt="" /> &nbsp; <span> whatsapp </span>
								</li>
								<li className="">
									<ImgCmp src={IMGREF.FB} cls="tp-0" alt="" /> &nbsp; <span>  facebook </span>
								</li>
								<li className="">
									<ImgCmp src={IMGREF.TW} cls="tp-0" alt="" /> &nbsp; <span>  twitter </span>
								</li>
								<li className="">
									<ImgCmp src={IMGREF.LK} cls="tp-0" alt="" /> &nbsp; <span>  linkedin </span>
								</li>
							</ul>
						</div>

						<div className="col-md-3 footer-list">
							<p className='bold ssmtext'> help </p>
							<ul className="ul nofont footer-bd">
								<li className="popins"> faqs </li>
								<li className="popins"> privacy </li>
								<li className="popins"> support </li>
								<li className="popins"> register </li>
							</ul>
						</div>

						<div className="col-md-3 footer-list">

							<p className='bold ssmtext'> product </p>
							<ul  className="ul">
								<li className="popins"> what's new? </li>
								<li className="popins"> developer/api  </li>
								<li className="popins"> api status </li>
								<li className="popins"> terms & conditions </li>
							</ul>
						</div>
						<ImgCmp src={IMGREF.LOGOWK} className={'img-fluid'} style={{width: '450px', position: 'absolute', bottom: 0, left: "-30px"}} />


				  	</div>
				 
	            </div>

			</div>
			<ImgCmp src={IMGREF.CIRCLE_LEFT}  style={{position: "absolute", top:"-40px", right: "0px", zIndex: 2, height:"80%"}}/>
		 </section>
        );

}


export function FAQ(props){

	return (	<section>
			
			<div className="container" >
				<div className='row'>
					<div className='col-md-12 col-12'>								
						<div className="center">							
							<h1 className="bold montsert-bold mt10 mbt5"> frequently asked questions </h1>
							
						</div> 

						<Accordion>
						  <Accordion.Item eventKey="0">
						    <Accordion.Header>
						    	 <h4 className="montsert-reg"> what is leapePay </h4>
						    </Accordion.Header>
						    <Accordion.Body>
						    	<p className='ssmtext montsert-reg'>
						    		leapePay is a lifestyle payment soluction provider that processes payments simply and securely. 
						    		The leapePay is targeted at individuals, businesses and merchants. To make card acceptance more easily 
						    		integrated with lower costs and more importantly minimal fraud.
						    		<br/>
						    		Services provided include secure payment gateway, fraud preventions systems with convenient payment options and ecommerce 
						    		platforms tailor-made to suit business needs.
						    	</p>
						    </Accordion.Body>
						  </Accordion.Item>
						  <Accordion.Item eventKey="1">
						    <Accordion.Header>
						    	<h4 className="montsert-reg"> which credit card will I be able to accept </h4> 
						    </Accordion.Header>
						    <Accordion.Body>
						    <p className="ssmtext montsert-reg">
						    	As a merchant on our platform, you will be able to accept major credit/debit cards from MasterCard, Visa and Verve for the time being.
						    	We will be adding more card processors shortly.
						    </p>
						    </Accordion.Body>
						  </Accordion.Item>
						  <Accordion.Item eventKey="2">
						    <Accordion.Header>
						    	<h4 className="montsert-reg"> how can i register for leapePay and all its products, do I require separete logins/signups?  </h4> 
						    </Accordion.Header>
						    <Accordion.Body>
						      <p className='ssmtext montsert-reg'>
						      	you do not need multiple sign-ups  to access all our products and services, for ease of use one login for everthing leapepay. 
						      	registration/sign up to leapepay and all its products is a simple and easy process. 
						      	<br/>
						      	Please click here to register online and a sales representative will contact you to further assist you 
						      	and provide you with the required support.

						      </p>
						    </Accordion.Body>
						  </Accordion.Item>
						  <Accordion.Item eventKey="3">
						    <Accordion.Header>
						    	<h4 className="montsert-reg"> what are the charges for using leapepay for my business </h4> 
						    </Accordion.Header>
						    <Accordion.Body>
						       <p className="ssmtext montsert-reg">
						       		we offer up to 10 free transfers to individuals and merchants on our platform, 
						       		to see what you pay when you use leapepay.

						       </p>
						    </Accordion.Body>
						  </Accordion.Item>
						  <Accordion.Item eventKey="4">
						    <Accordion.Header>
						    	<h4 className="montsert-reg"> why was my transaction declined </h4> 
						    </Accordion.Header>
						    <Accordion.Body>
						   		<p className="ssmtext montsert-reg">
						       		you can refer to your merchant dashboard or personal leapepay dashboard and click the View 
						       		button against the specific transaction and view the reason for rejection/decline.
						       		Additionally, you may contact customercare@leapepay.com  for more information.


						        </p>
						    </Accordion.Body>
						  </Accordion.Item>

						</Accordion>
 
					</div>
				</div>

			</div>

		</section>
	)
}

export default Home

//https://www.figma.com/proto/jESxxPKeBtHhnkfhp93nmO/hazal-consult?page-id=1266%3A1146&node-id=1292%3A3886&viewport=241%2C48%2C0.26&scaling=scale-down&starting-point-node-id=1307%3A6995