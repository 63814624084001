
import WALLET from './images/e_wallet.png'
import LEAPEX from "./images/leapeX.png";
import PAYVENUE from "./images/payvenue.png";
import LOGO from "./images/logo.png"
import EASY from "./images/easy.png";
import HIDDENFEE from "./images/nohidden_fees.png";
import SECURITY from "./images/security.svg";
import INTEGRATION from "./images/integration.png";
import DOTTEDBG from "./images/dottedlayer.png";
import PARTNERS from './images/partners.png'; 
import GIRL from './images/girl.png'; 
import MAN from './images/man.png'; 
import GIRLSMILE from "./images/girlsmiles.png"
import MANSMILE from "./images/mansmiles.png"
import MANTHINK from "./images/manthinks.png"
import LOGOWK from "./images/logowatermark.png"
import WP from "./images/whatsapp.svg"
import FB from "./images/facebook.svg"
import TW from "./images/twitter.svg"
import LK from "./images/linkedin.svg"
import BOYGIRL from "./images/boygirl.png"
import PHONEMAN from "./images/phoneman.png"
import MOBILE from "./images/mobile.png"
import REMOTE from "./images/remotewk.png"
import SHOPING from "./images/shopping.png"
import SERIOUSMAN from "./images/seriousman.png"; 
import FLYINGGIRL from "./images/flyinggirl.png";
import OFFICEGIRL from "./images/officegirl.jpg";
import CIRCLE from './images/circle.svg';
import CIRCLE_LEFT from "./images/circular_left.svg"
import CIRCLE_RIGHT from "./images/circular_right.png"
import MEETROOM from "./images/meetroom.png"
import TRANSP from "./images/transparency.svg"
import TEAMSP from "./images/teamspirit.svg"
import RELIABLE from "./images/reliability.svg"
import GMINDS from "./images/greatmind.svg"
import HEALTH from "./images/health.svg"
import WGADETS from "./images/gadget.svg"
import ENVELOPE from "./images/mail.svg"
import MARKER from "./images/marker.svg"
import LAPTOPTICS from "./images/laptop_point.png"
import BOYDEV from "./images/boy_dev.png"


const IMG = {
	LOGO,
	WALLET,
	LEAPEX,
	PAYVENUE,
	EASY,
	HIDDENFEE,
	SECURITY,
	INTEGRATION,
	DOTTEDBG,
	PARTNERS,
	GIRL, MAN,
	GIRLSMILE, MANSMILE, MANTHINK,
	LOGOWK,
	WP,FB,TW,LK,
	BOYGIRL, PHONEMAN,
	MOBILE,
	REMOTE,
	SHOPING,
	SERIOUSMAN,
	FLYINGGIRL,
	OFFICEGIRL,
	CIRCLE, CIRCLE_LEFT,CIRCLE_RIGHT,
	MEETROOM,
	TRANSP, TEAMSP, RELIABLE, GMINDS, HEALTH, WGADETS,
	ENVELOPE, MARKER,
	LAPTOPTICS,
	BOYDEV

}

export default IMG


